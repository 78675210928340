import React from 'react'

const ViewSubscription = () => {
  return (
    <div >
      <iframe
      title='Subscription'
        src="https://pay.stripe.com/receipts/invoices/CAcaFwoVYWNjdF8xTmtoMjFSMkZmcEF4YVFNKIeA-7kGMgZtLtzoBxM6LBbQTg7-XyVB_JnKqGZQZJzqWri4z06A7Hn4wkMZNIXT9zv0yDQPSG3gzbSU?s=ap"
        width="100%"
        height="500px"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      >

      </iframe>
    </div>
  )
}

export default ViewSubscription
