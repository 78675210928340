import React, { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Register = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [registered, setRegistered] = useState(false);
  const [email, setEmail] = useState(""); // Store email for resending verification email
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(() => {
    const savedTimer = localStorage.getItem("registerTimer");
    return savedTimer ? JSON.parse(savedTimer) : 0;
  });

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const passwordRef = useRef();
  passwordRef.current = watch("password", "");

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/auth/register`,
        data
      );
      toast.success(res.data.message);
      setEmail(data.email); // Set email for resending verification email
      setRegistered(true); // Set registered state to true
      setLoading(false);
      setTimer(30); // Set timer for 30 seconds
      localStorage.setItem("registerTimer", JSON.stringify(30));
      localStorage.setItem("registerTimerStart", Date.now());
    } catch (error) {
      setLoading(false);
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "An unexpected error occurred";
      toast.error(errorMessage);
    }
  };

  const resendVerificationEmail = async () => {
    try {
      setLoading(true);
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/auth/resend-verification`,
        { email }
      );
      toast.success(res.data.message);
      setLoading(false);
      setTimer(30); // Reset timer for 30 seconds
      localStorage.setItem("registerTimer", JSON.stringify(30));
      localStorage.setItem("registerTimerStart", Date.now());
    } catch (error) {
      setLoading(false);
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "An unexpected error occurred";
      toast.error(errorMessage);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const savedTimerStart = localStorage.getItem("registerTimerStart");
      if (savedTimerStart) {
        const elapsed = Math.floor((Date.now() - savedTimerStart) / 1000);
        const newTimer = 30 - elapsed;
        if (newTimer <= 0) {
          setTimer(0);
          localStorage.removeItem("registerTimer");
          localStorage.removeItem("registerTimerStart");
        } else {
          setTimer(newTimer);
          localStorage.setItem("registerTimer", JSON.stringify(newTimer));
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="py-8 px-4 flex items-center justify-center">
      <ToastContainer />
      {registered ? (
        <div className="text-center">
          <h2>Check your email for verification</h2>
          <p>
            If you did not receive the email, click below to resend the
            verification email.
          </p>
          <button
            onClick={resendVerificationEmail}
            className="mt-4 bg-black text-white rounded-md py-2 px-4"
            disabled={loading || timer > 0}
          >
            {loading ? (
              <i className="fa-solid fa-spinner animate-spin"></i>
            ) : timer > 0 ? (
              `Didn't receive the email? Try again in ${timer}s`
            ) : (
              "Resend Verification Email"
            )}
          </button>
        </div>
      ) : (
        <form
          className="bg-gray-100 p-8 rounded-md w-full max-w-md"
          onSubmit={handleSubmit(onSubmit)}
        >
          <h2 className="text-center">Signup to create an account</h2>
          <p className="text-center">
            Already have an account?{" "}
            <Link to="/login" className="underline">
              Login
            </Link>
          </p>
          <div className="mb-4">
            <input
              type="text"
              {...register("name", { required: "Name is required" })}
              className="w-full py-2 px-3 border border-gray-300 rounded outline-none focus:outline-none"
              placeholder="Name"
            />
            {errors.name && (
              <p className="text-red-500 text-sm mt-1">{errors.name.message}</p>
            )}
          </div>
          <div className="mb-4">
            <input
              type="email"
              {...register("email", {
                required: "Email is required",
                pattern: { value: /^\S+@\S+$/i, message: "Invalid email" },
              })}
              className="w-full py-2 px-3 border border-gray-300 rounded outline-none focus:outline-none"
              placeholder="Email"
            />
            {errors.email && (
              <p className="text-red-500 text-sm mt-1">
                {errors.email.message}
              </p>
            )}
          </div>
          <div className="mb-4">
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                {...register("password", {
                  required: "Password is required",
                  minLength: {
                    value: 6,
                    message: "Password must be at least 6 characters",
                  },
                })}
                className="w-full py-2 px-3 border border-gray-300 rounded outline-none focus:outline-none"
                placeholder="Password"
              />
              {passwordRef.current && (
                <span
                  onClick={togglePasswordVisibility}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                >
                  {showPassword ? (
                    <i className="fa-regular fa-eye text-gray-400"></i>
                  ) : (
                    <i className="fa-regular fa-eye-slash text-gray-400"></i>
                  )}
                </span>
              )}
            </div>
            {errors.password && (
              <p className="text-red-500 text-sm mt-1">
                {errors.password.message}
              </p>
            )}
          </div>
          <div className="mb-2">
            <label className="flex items-center">
              <input
                type="checkbox"
                {...register("privacyPolicy", {
                  required: "You must agree to the privacy policy",
                })}
                className="mr-2"
              />
              <span className="text-sm text-gray-700">
                I have read and agree to the{" "}
                <a href="/privacy-policy" className="underline">
                  privacy policy
                </a>
                .
              </span>
              
            </label>
            {errors.privacyPolicy && (
              <p className="text-red-500 text-sm mt-1">
                {errors.privacyPolicy.message}
              </p>
            )}
          </div>
          <div className="mb-4">
          <span className="text-sm text-gray-700">
                by creating an account with us, you are agreeing to our terms and conditions {' '}
                <a href="/terms" className="underline">
                here
                </a>
                .
              </span>
          </div>
          <button
            type="submit"
            className={`mt-2 w-full bg-black text-white py-2.5 px-3 rounded`}
            disabled={loading}
          >
            {loading ? (
              <i className="fa-solid fa-spinner animate-spin"></i>
            ) : (
              "Register"
            )}
          </button>
        </form>
      )}
    </div>
  );
};

export default Register;
