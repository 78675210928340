import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useCache } from "../../CacheContext";
import { loadStripe } from "@stripe/stripe-js";

const Subscription = () => {
  const navigate = useNavigate();
  const { setCacheData, getCacheData } = useCache();
  const [loading, setLoading] = useState(true);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [isCanceled, setIsCanceled] = useState(false);
  const [subscription, setSubscription] = useState([]);

  useEffect(() => {
    if (!subscription.length) {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/api/subscription`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          console.log("subscription api call made");
          setSubscription(response.data.data);
          setCacheData("subscription", response.data.data);
          setLoading(false);
        })
        .catch((error) => {
          const errorMessage =
            error.response?.data?.message ||
            error.message ||
            "An unexpected error occurred while fetching the stories";
          toast.error(errorMessage);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [subscription, setCacheData]);
  const cancelSubscription = async () => {
    setCancelLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/subscription/cancel`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if(response.data.success){
        setIsCanceled(true);

        toast.success("Subscription Cancelled Successfully");

        
      }
    } catch (error) {
      toast.error(error.response?.data?.message || error.message);
      
    } finally {
      setCancelLoading(false);
    }
  }
  const getExpirationInfo = (subscription) => {
    // If cancel_at exists, use that
    if (subscription.cancel_at) {
      return moment(subscription.cancel_at).format('MMMM Do YYYY');
    }
    
    // Otherwise, use current_period_end or default to one month from creation
    const expirationDate = subscription.current_period_end 
      ? moment(subscription.current_period_end)
      : moment(subscription.createdAt).add(1, 'month');
      console.log(expirationDate);
    
    return expirationDate.format('MMMM Do YYYY');
  };
  if (loading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <i className="fa-solid fa-spinner animate-spin text-2xl"></i>
      </div>
    );
  }

  if (subscription.length === 0) {
    return (
      <><div className="flex flex-col items-center justify-center fixed bottom-1/2 left-1/2 transform -translate-x-1/2 z-50">
        <p>
            Haven't subscribed to melo stories yet?{" "} Subscribe to get access to stories.
        </p>
      </div>
      
      </>
      
    );
  }
//   {
//     "_id": "6744ab8b85b62daa4165eba2",
//     "userId": "673179032d114ef3c42b7bd2",
//     "subscriptionId": "sub_1QP5XPR2FfpAxaQMVD9mY87S",
//     "status": "active",
//     "current_period_start": "1970-01-21T01:15:53.607Z",
//     "current_period_end": "1970-01-21T01:15:53.607Z",
//     "payment_intent": "pi_3QP5XPR2FfpAxaQM2poZDMTq",
//     "invoiceId": "in_1QP5XPR2FfpAxaQMH4cjz5fQ",
//     "amount": 4500,
//     "createdAt": "2024-11-25T16:53:31.407Z",
//     "updatedAt": "2024-11-25T16:53:32.039Z",
//     "__v": 0
// }
  return (
    <div className="max-w-4xl mx-auto p-4">
    <ToastContainer />
    <h3 className="text-center font-semibold leading-6 text-gray-900">
      Your Subscriptions
    </h3>
    {
      isCanceled ? ( <div
      className="bg-teal-50 border-t-4 border-red-500 rounded-b px-4 py-3 shadow-sm"
      role="alert"
    >
      <div className="flex gap-2 items-center">
        <i className="fa-solid fa-circle-info text-red-500"></i>
        <p className="text-sm m-0 text-red-500">
          Your request to cancel the subscription has been processed. It will be cancelled at the end of the current billing cycle.
        </p>
      </div>
    </div>):( <div
      className="bg-teal-50 border-t-4 border-blue-500 rounded-b px-4 py-3 shadow-sm"
      role="alert"
    >
      <div className="flex gap-2 items-center">
        <i className="fa-solid fa-circle-info text-blue-500"></i>
        <p className="text-sm m-0 text-blue-500">
          Sometimes new subscriptions may not appear immediately so we
          recommend you to wait 2 minutes and then refresh the page.
        </p>
      </div>
    </div>)
    }
   

    <div className="pt-4 w-full">
      <div className="flex flex-col items-center justify-center w-full">
        {subscription.map((subscription) => (
          <div
            key={subscription._id}
            className="border border-gray-200 shadow-sm p-4 rounded mb-6 w-full"
          >
            <div className="flex items-center w-full">
              <div className="flex flex-row justify-between w-full gap-4">
                <div className="w-1/2">
                  <p className="text-sm leading-normal">
                    Status: <span className="text-gray-500">{subscription.status}</span>
                  </p>
                  <p className="text-sm leading-normal">
                    Created:{" "}
                    <span className="text-gray-500">
                      {moment().diff(moment(subscription.createdAt), "hours") < 24
                        ? moment(subscription.createdAt).fromNow()
                        : moment(subscription.createdAt).format("MMMM Do YYYY")}
                    </span>
                  </p>
                  <p className="text-sm leading-normal">
                    Amount: <span className="text-gray-500">${(subscription.amount / 100).toFixed(2)}</span>
                  </p>
                  <p className="text-sm leading-normal">
                    Expires: <span className="text-gray-500">{getExpirationInfo(subscription)}</span>
                  </p>
                  {moment().diff(moment(subscription.createdAt), "minutes") < 15 && (
                    <span className="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded">
                      Purchased Just Now
                    </span>
                  )}
                </div>
                <div className="flex flex-col items-center justify-center gap-4">
                  {subscription.invoice_url && (
                    <button
                      onClick={() => {
                        window.open(subscription.invoice_url, "_blank");
                      }}
                      className="rounded-md transition-all bg-black px-3 py-2 font-semibold text-white shadow-sm hover:opacity-75 text-sm"
                    >
                      View Invoice
                    </button>
                  )}
                  {subscription.status === 'active' ? (
                    <button
                      disabled={cancelLoading}
                      onClick={() => {
                        cancelSubscription(subscription.subscriptionId);
                      }}
                      className="rounded-md transition-all bg-white px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                      {
                        cancelLoading ? (
                          <i className="fa-solid fa-spinner animate-spin text-sm"></i>
                        ) : (
                          "Cancel Subscription"
                        )
                      }
                    </button>
                  ) : (
                    <button
                      className="rounded-md transition-all w-full bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-700 shadow-sm"
                      disabled
                    >
                      Subscription {subscription.status}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
  );
};

export default Subscription;
