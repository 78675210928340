const TermsAndConditions = () => {
    return (
      <div className="max-w-4xl mx-auto px-4 py-8">
        <div className="mb-8 text-center">
        <h3 className="text-center font-playfair font-bold">Terms and Conditions</h3>
          <p>Last updated: 19/11/2024</p>
        </div>
        
        <div className="space-y-6">
          <p >
            Welcome to melostories.com.au ("Website"). By accessing and using this Website, you
            agree to comply with and be bound by the following Terms and Conditions. Please read
            these terms carefully. If you do not agree with any part of these terms, you must not use this
            Website.
          </p>
  
          <div className="space-y-6">
            <section>
              <h4
              className="font-playfair"
              >1. Acceptance of Terms</h4>
              <p >
                By accessing, browsing, or using this Website, you acknowledge that you have read,
                understood, and agree to be bound by these Terms and Conditions, including any future
                modifications. These Terms apply to all users of the Website.
              </p>
            </section>
  
            <section>
              <h4 className="font-playfair">2. Service Overview</h4>
              <p >
                melostories.com.au provides an interactive service where users engage with an AI chatbot
                to create personalised stories. Users may upload photos and provide personal information to
                enhance the storytelling experience.
              </p>
            </section>
  
            <section>
              <h4 className="font-playfair">3. User Content</h4>
              <ul className="list-disc pl-6 space-y-2 text-gray-700">
                <li>
                  <span className="font-semibold">Ownership:</span> Users retain full ownership of all photos, stories, and information they
                  upload to the Website ("User Content").
                </li>
                <li>
                  <span className="font-semibold">Usage Rights:</span> By uploading User Content, you grant melostories.com.au a
                  non-exclusive, royalty-free, worldwide licence to store, display, and use your content
                  strictly for the purpose of providing the storytelling service.
                </li>
                <li>
                  <span className="font-semibold">Non-Distribution Clause:</span> melostories.com.au will not publish, share, or distribute
                  your User Content without your explicit written consent.
                </li>
              </ul>
            </section>
  
            <section>
              <h4 className="font-playfair">4. Privacy and Data Security</h4>
              <ul className="list-disc pl-6 space-y-2 text-gray-700">
                <li>
                  <span className="font-semibold">Data Retention:</span> We retain all chat histories, stories, and photos for service
                  continuity, including potential updates, edits, or future access by users.
                </li>
                <li>
                  <span className="font-semibold">Data Protection:</span> We implement industry-standard measures to protect your User
                  Content from unauthorised access, disclosure, or alteration.
                </li>
                <li>
                  <span className="font-semibold">Third-Party Access:</span> We do not share your information with third parties without your
                  explicit consent, except as required by law.
                </li>
              </ul>
            </section>
  
            <section>
              <h4 className="font-playfair">5. User Conduct</h4>
              <p className="font-bold mb-2">Users agree to:</p>
              <ul className="list-disc pl-6 space-y-2 text-gray-700">
                <li>Use the Website only for lawful purposes.</li>
                <li>Refrain from uploading any content that is illegal, offensive, or infringes on the rights
                  of others.</li>
              </ul>
            </section>
  
            <section>
              <h4 className="font-playfair">6. Intellectual Property</h4>
              <p >
                All text, graphics, interfaces, and software used on the Website, excluding User Content, are
                the property of melostories.com.au and are protected by applicable intellectual property
                laws. Users agree not to reproduce, distribute, or create derivative works based on this
                content without prior written permission.
              </p>
            </section>
  
            <section>
              <h4 className="font-playfair">7. Disclaimer of Warranties</h4>
              <p >
                melostories.com.au provides the Website and services "as is" without warranties of any
                kind, either express or implied, including but not limited to the implied warranties of
                merchantability, fitness for a particular purpose, and non-infringement.
              </p>
            </section>
  
            <section>
              <h4 className="font-playfair">8. Limitation of Liability</h4>
              <p >
                melostories.com.au will not be liable for any direct, indirect, incidental, or consequential
                damages arising from your use of, or inability to use, the Website or the services provided.
              </p>
            </section>
  
            <section>
              <h4 className="font-playfair">9. Changes to the Terms</h4>
              <p >
                We reserve the right to modify these Terms at any time. Changes will be posted on this
                page, and it is the responsibility of users to review these terms periodically.
              </p>
            </section>
  
            <section>
              <h4 className="font-playfair">10. Contact Information</h4>
              <p >
                If you have any questions regarding these Terms and Conditions, please contact us at
                hello@hellomelo.com.au
              </p>
            </section>
          </div>
        </div>
      </div>
    );
  };
  
  export default TermsAndConditions;