import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useParams } from "react-router-dom";
import { useCalendlyEventListener, InlineWidget } from "react-calendly";
import { useCache } from "../../CacheContext";

const FilmBooking = () => {
  const [user, setUser] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const [calendlyHeight, setCalendlyHeight] = useState("700px");

  const { getCacheData, setCacheData, clearCacheData } = useCache();


  // useEffect(() => {
  //   axios
  //     .get(`${process.env.REACT_APP_BACKEND_URL}/api/auth/user`, {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("token")}`,
  //       },
  //     })
  //     .then((response) => {
  //       setUser(response.data.data);
  //     })
  //     .catch((error) => {
  //       const errorMessage =
  //         error.response?.data?.message ||
  //         error.message ||
  //         "An unexpected error occurred while fetching the user.";
  //       toast.error(errorMessage);
  //     });
  // }, []);

  const createOrder = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/order`,
        {
          productName: "Film",
          totalAmount: 1250,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      clearCacheData("orders");
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "An unexpected error occurred";
      toast.error(errorMessage);
    }
  };



  useCalendlyEventListener({
    onPageHeightResize: (e) => setCalendlyHeight(e.data.payload.height),
    onEventScheduled: (e) => {
      createOrder();
    },
  });






  const toggleStoryContent = () => {
    setOpen(!open);
  };

  return (
    <div className="container mx-auto flex items-center justify-center flex-col space-y-2 relative px-4 py-8">
      <ToastContainer />
      <p
      className="self-start"
      >
  Already have a story to turn into a film?{" "}
  <Link to={'/turn-your-story-into-a-film/stories'}>
    Click here
  </Link>
</p>
      <div className="flex flex-col gap-16 sm:gap-0 sm:flex-row w-full justify-center">
        <video
          controls
          autoPlay
          muted
          playsInline
          preload="auto"
          className="max-h-[600px] mb-4"
        >
          <source
            src="https://melo-story.s3.eu-north-1.amazonaws.com/DRAFT+4+(1)+(1)+(2).mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>

        <InlineWidget
          // url="https://calendly.com/sufiyanirfan1613/free-consultation"
          url="https://calendly.com/hellomelostorytelling/chapter-one-film-photo-package-clone"
          prefill={{
            email: user?.email,
            name: user?.name,
          }}
          styles={{ height: calendlyHeight, width: "100%" }}
          pageSettings={{
            hideGdprBanner: true,
            backgroundColor: "ffffff",
            primaryColor: "000000",
            textColor: "000000",
          }}
        />
      </div>

    </div>
  );
};

export default FilmBooking;
