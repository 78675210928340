import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ResultScreen.css";
import { AuthContext } from "../../context/AuthContext";

const ResultScreen = ({ finalMessg, setStep,prompt,stepKey,finalMessgKey }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState(null);
    const location=useLocation();

  const {allowedStories,isAuthenticated,setSubscriptionActive,setAllowedStories,firstStory,setFirstStory} = useContext(AuthContext);
  // const [product, setProduct] = useState(null);

  // useEffect(() => {
  //   axios
  //     .get(`${process.env.REACT_APP_BACKEND_URL}/api/category/story`)
  //     .then((response) => {
  //       const result = response.data.data;
  //       setProduct(result[0]);
  //     })
  //     .catch((error) => {
  //       const errorMessage =
  //         error.response?.data?.message ||
  //         error.message ||
  //         "An unexpected error occurred while fetching the story.";
  //       toast.error(errorMessage);
  //     });
  // }, []);
  useEffect(() => {
    if (!product) {
      const storedProduct = localStorage.getItem("checkoutProduct");
      if (storedProduct) {
        setProduct(JSON.parse(storedProduct));
      }
    }
  }, [product, navigate]);
  const handlePurchaseClick = () => {
    // const productDetails = {
    //   name: product?.name,
    //   price: product?.basePrice,
    //   description: product?.description,
    // };
    const productDetails = {
      name: "Story Bundle",
      price: 19,
      description: `<ol class="ml-4">
            <li class="text-sm text-gray-500">Your Story</li>
            <li class="text-sm text-gray-500">Your Values</li>
            <li class="text-sm text-gray-500">Your content strategy</li>
            <li class="text-sm text-gray-500">
              Your digital media schedule
            </li>
          </ol>`,
        prompt: prompt
    };
    localStorage.removeItem("checkoutProduct"); // Clear only checkoutProduct
    localStorage.setItem("checkoutProduct", JSON.stringify(productDetails));
    localStorage.setItem("tab", location.search.split("=")[1]);
    navigate("/checkout");
  };
  const addStory = async () => {
    try {
      setLoading(true);
      const requestBody = { product };
      if (product.name === "Story Bundle") {
        requestBody.userMessage = `You are a skilled storyteller. You will be given chat history which contains user interaction with the chat. Now, based on that conversation, you will generate a story.
      Please format the response using only the following HTML tags: <h4> for headings, <p> for paragraphs, and <ul> or <ol> with <li> for lists and bullet points or numbering items. Ensure that the content is structured and formatted clearly using these tags. Make sure to include the specified headings and follow the given structure for the story.
    
            Here is the structure to follow:
    
            <h4>Short story name (maximum 10 words)</h4> 
            [[Minimum 500 words for the story]]
            <p>Paragraph1</p>
            <p>Paragraph2</p>
            <p>Paragraph3</p>

            <h4>Your values</h4>
            <ol>
              <li>Value 1</li>
              <li>Value 2</li>
              <li>Value 3</li>
            </ol>
    
            <h4>Your content strategy</h4>
            <ol>
              <li>Strategy 1</li>
              <li>Strategy 2</li>
              <li>Strategy 3</li>
              <!-- Provide at least 20 content strategy ideas -->
            </ol>
    
            <h4>Your digital media schedule</h4>
            <ul>
              <li>Monday: Schedule details</li>
              <li>Tuesday: Schedule details</li>
              <li>Wednesday: Schedule details</li>
              <li>Thursday: Schedule details</li>
              <li>Friday: Schedule details</li>
              <li>Saturday: Schedule details</li>
              <li>Sunday: Schedule details</li>
            </ul>
            
            Important Note: Generate a positive, engaging, and appropriate story based on the user conversation with the chatgpt system. Avoid any content that could be considered inappropriate, offensive, or harmful. Do not include any introductory phrases, explanations, or ask for any further input or clarification. Start directly with the story content as per the provided structure and chat history. Do not include any concluding sentences or summaries such as "This concludes the story" or "If you need any adjustments or further assistance, feel free to let me know." End the story naturally without any closing remarks.
            `;
        requestBody.chatHistory = JSON.parse(
          localStorage.getItem(product.prompt)
        );
        requestBody.introStoryLines = localStorage.getItem(`introStoryLines_${product.prompt}`);
        requestBody.firstStory=firstStory;
      }
      
       axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/story/add-story`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setTimeout(() => {
    toast.success('Story has been added');
        setLoading(false);
        if(!firstStory){
          localStorage.setItem("allowedStories", allowedStories - 1);
          setAllowedStories(allowedStories - 1);
        }
        else{
          setFirstStory(false);
          localStorage.setItem("firstStory", false);
        }
        navigate("/story");
}, 6000);
        
      
       
      
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "An unexpected error occurred";
      toast.error(errorMessage);
      setLoading(false);
    }
  }
  return (
    <div className="max-w-4xl mx-auto flex items-center justify-center flex-col space-y-2 relative px-4 py-8">
      <ToastContainer />

      <div
        dangerouslySetInnerHTML={{ __html: finalMessg }}
        className="text-content pb-16 blurred-content single-story"
      />

      <div className="max-w-4xl mx-auto pt-4 pb-8 fixed bottom-14 left-0 right-0 w-full bg-white border-t border-gray-200">
        <div className="flex items-center justify-center gap-4">
          <button
            onClick={
              () => {
                if((isAuthenticated &&  allowedStories > 0)||(isAuthenticated &&  firstStory)) {
                  addStory();
                }else{
                  handlePurchaseClick();
                }
              }
            }
            disabled={loading}
            className="font-lato rounded-md transition-all bg-black px-3 py-2 font-semibold text-white shadow-sm hover:opacity-75 text-sm"
            


          >
            {
              (isAuthenticated &&  allowedStories) || (isAuthenticated &&  firstStory) > 0 ? loading? (<i className="fa-solid fa-spinner animate-spin"></i>):"Get my story" : "Get my story"
            }
          </button>
          <button
            onClick={() => {
              setStep(1);
    localStorage.removeItem(prompt);
    localStorage.removeItem(stepKey);
    localStorage.removeItem(finalMessgKey);
    localStorage.removeItem(`introStoryLines_${prompt}`);
            }}
            className="font-lato rounded-md transition-all bg-white px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
         
          >
            Start again
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResultScreen;
