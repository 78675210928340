import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom"
import { toast } from "react-toastify";


export default function SingleGift() {
  const { id } = useParams();
  const [pageLoading, setPageLoading] = useState(true);
  const [gift, setGift] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      setPageLoading(true);
      try {
        console.log("api call made");
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/gift/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setGift(response.data.data);
        
        setPageLoading(false);
        
      } catch (error) {
        const errorMessage =
          error.response?.data?.message ||
          error.message ||
          "An unexpected error occurred while fetching the story.";
        toast.error(errorMessage);
        setPageLoading(false);
      }
    };
    if (!gift) {
      fetchData();
    } else {
      
      setPageLoading(false);
    }
  }, [id, gift]);


  if (pageLoading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <i className="fa-solid fa-spinner animate-spin text-2xl"></i>
      </div>
    );
  }
  if (!gift) {
    return (
      <div className="h-screen flex items-center justify-center">
        <p>No story found</p>
      </div>
    );
  }
  return (
    <div className="max-w-4xl mx-auto px-4 py-8 single-story">
      <h3
      className="text-center"
      >Your Gift Purchase</h3>

      <div className="space-y-8">
        <section>
          <h4>Gift Details</h4>
          <div className="border p-6">
            <div className="aspect-w-16 aspect-h-9 mb-6">
              <img
                src={gift.fileUrl}
                alt="Your gift"
                
                className="rounded-lg"
              />
            </div>
            <h4>{gift.storyId.name}</h4>
            <Link to={`/story/${gift.storyId._id}`} className="hover:underline">
              View Full Story
            </Link>
          </div>
        </section>

        <section>
          <h4>Order Information</h4>
          <div className="border p-6 space-y-2">
            <p><span className="font-semibold">Order ID:</span> {gift._id}</p>
            <p><span className="font-semibold">Order Date:</span> {gift.createdAt}</p>
            {/* <p><span className="font-semibold">Status:</span> {giftPurchase.status.charAt(0).toUpperCase() + giftPurchase.status.slice(1)}</p> */}
          </div>
        </section>

        <section>
          <h4>Delivery Information</h4>
          <div className="border p-6">
            <p className="mb-4">
              <span className="font-semibold">Delivery Method:</span>{' '}
              {gift.shippingMethod === 'shipping' ? 'Shipping' : 'Local Pickup'}
            </p>
            {gift.shippingMethod === 'shipping' && (
              <div>
                <p className="font-semibold mb-2">Shipping Address:</p>
                <p>{gift.street}</p>
                <p>{gift.city}, {gift.state} {gift.postalCode}</p>
              </div>
            )}
            {gift.shippingMethod === 'pickup' && (
              <p>
                <span className="font-semibold">Pickup Location:</span> 166 Belmore Road, Riverwood
              </p>
            )}
          </div>
        </section>

        {/* <section className="flex justify-center space-x-4">
          <button className="px-6 py-2 border border-black hover:bg-black hover:text-white transition-colors">
            Contact Support
          </button>
          {giftPurchase.deliveryMethod === 'shipping' && giftPurchase.status !== 'delivered' && (
            <button className="px-6 py-2 bg-black text-white hover:bg-gray-800 transition-colors">
              Track Shipment
            </button>
          )}
        </section> */}
      </div>
    </div>
  )
}

