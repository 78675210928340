import React, { useState } from 'react';
import { BiComment, BiLike, BiShare } from 'react-icons/bi';
import { FcLike } from 'react-icons/fc';

const InstagramModal = ({ isOpen, onClose, item }) => {
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
console.log(item)
  if (!isOpen) return null;

  const nextMedia = () => {
    setCurrentMediaIndex((prevIndex) => 
      (prevIndex + 1) % item.media.length
    );
  };

  const prevMedia = () => {
    setCurrentMediaIndex((prevIndex) => 
      (prevIndex - 1 + item.media.length) % item.media.length
    );
  };
  console.log(item);
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-[10000] flex items-center justify-center p-4"
        
    >
      <div className="bg-white rounded-lg max-w-lg w-full max-h-[90vh] overflow-y-auto">
        <div className="p-4 border-b">
          <div className="flex items-center justify-between">
            <a href={item.profileLink} target="_blank" rel="noopener noreferrer" className="flex items-center space-x-2">
              <img src={item?.profilePicture} alt={item.username} className="w-10 h-10 rounded-full" />
              <span className="font-semibold">{item.username}</span>
            </a>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
        <div className="relative">
          {item.media[currentMediaIndex].video ? (
            <video src={item.media[currentMediaIndex].imgSrc} 
              autoPlay
              muted
              playsInline
              preload="metadata"
              className="w-full h-auto" />
          ) : (
            <img src={item.media[currentMediaIndex].imgSrc} alt="" className="w-full h-auto" />
          )}
          {item.media.length > 1 && (
            <>
              <button onClick={prevMedia} className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-1 shadow-md">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
              </button>
              <button onClick={nextMedia} className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-1 shadow-md">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                </svg>
              </button>
            </>
          )}
        </div>
        <div className="p-4">
        <div className="flex justify-between items-center mb-4">
            <div className="flex space-x-4">
              <button className="flex items-center space-x-1">
                <BiLike />
                <span>{item.likes} likes</span>
              </button>
              <button className="flex items-center space-x-1">
                <BiComment />
                <span>{item.comments} comments</span>
              </button>
            </div>
            <button className="flex items-center space-x-1">
              <BiShare />
              <span>{item.shares} shares</span>
            </button>
          </div>
          <p className="text-sm">{item.caption}</p>
        </div>
      </div>
    </div>
  );
};

export default InstagramModal;

