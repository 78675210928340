import React, { createContext, useState, useEffect } from 'react';
import {jwtDecode} from 'jwt-decode';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [subscriptionActive, setSubscriptionActive] = useState(false);
  const [allowedStories, setAllowedStories] = useState(0);
  const [firstStory,setFirstStory]=useState(false);
  const [loading, setLoading] = useState(true); // Add loading state
  const [logoutTimer, setLogoutTimer] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const allowedStories = localStorage.getItem('allowedStories');
    const firstStory = localStorage.getItem('firstStory');
    const subscriptionActive = localStorage.getItem('subscriptionActive');
    if (token) {
      try {
        const decoded = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        if (decoded.exp > currentTime) {
          setIsAuthenticated(true);
          setAllowedStories(allowedStories);
          setFirstStory(firstStory);
          setSubscriptionActive(subscriptionActive);
          const expirationTime = decoded.exp * 1000 - Date.now();
          setLogoutTimer(setTimeout(logout, expirationTime));
        } else {
          localStorage.removeItem('token');
        }
      } catch (error) {
        localStorage.removeItem('token');
      }
    }
    setLoading(false); // Set loading to false after checking token
  }, []);

  const login = (token,allowedStories,subscriptionActive,firstStory) => {
    localStorage.setItem('token', token);
    localStorage.setItem('allowedStories', allowedStories);
    localStorage.setItem('subscriptionActive', subscriptionActive);
    localStorage.setItem('firstStory', firstStory);
    setIsAuthenticated(true);
    setAllowedStories(allowedStories);
    setSubscriptionActive(subscriptionActive);
    setFirstStory(firstStory);
    const decoded = jwtDecode(token);
    const expirationTime = decoded.exp * 1000 - Date.now();
    setLogoutTimer(setTimeout(logout, expirationTime));
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('allowedStories');
    localStorage.removeItem('firstStory');
    localStorage.removeItem('subscriptionActive');
    setIsAuthenticated(false);

    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
  };
  return (
    <AuthContext.Provider value={{ isAuthenticated, loading, login, logout,allowedStories,setAllowedStories,subscriptionActive, setSubscriptionActive,firstStory,setFirstStory}}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
