import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useCache } from "../../CacheContext";

const Gift = () => {
  const navigate = useNavigate();
  const { setCacheData, getCacheData } = useCache();
  const [loading, setLoading] = useState(true);

  const [stories, setStories] = useState(() => getCacheData("stories") || []);

  useEffect(() => {
    if (!stories.length) {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/api/gift`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          setStories(response.data.data);
          setCacheData("stories", response.data.data);
          setLoading(false);
        })
        .catch((error) => {
          const errorMessage =
            error.response?.data?.message ||
            error.message ||
            "An unexpected error occurred while fetching the stories";
          toast.error(errorMessage);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [stories, setCacheData]);

  if (loading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <i className="fa-solid fa-spinner animate-spin text-2xl"></i>
      </div>
    );
  }

  if (stories.length === 0) {
    return (
      <><div className="flex flex-col items-center justify-center fixed bottom-1/2 left-1/2 transform -translate-x-1/2 z-50">
        <p>
          Haven't created a gift yet?{" "}
        </p><Link
              to="/gift-your-story"
              className={`bg-black text-white py-2 px-4 text-center  font-lato transition-all duration-300`}
            >
              Create a Gift
            </Link>
      </div>
      
      </>
      
    );
  }


  const viewStory = (id) => {
    navigate(`/gift/${id}`);
  };

  return (
    <div className="max-w-4xl mx-auto p-4">
      <ToastContainer />
      <h3 className="text-center font-semibold leading-6 text-gray-900">
        Your Stories
      </h3>
      <div
        class="bg-teal-50  border-t-4 border-blue-500 rounded-b px-4 py-3 shadow-sm"
        role="alert"
      >
        <div class="flex gap-2 items-center">
          <i class="fa-solid fa-circle-info text-blue-500"></i>

          <p class="text-sm m-0 text-blue-500">
            {" "}
            Sometimes new purchased story may not appear immediately so we
            recommend you to wait 2 minutes and then refresh the page.
          </p>
        </div>
      </div>

      <div className="pt-4 w-full">
        <div className="flex flex-col items-center justify-center w-full">
          {stories.map((story) => (
            <div
              key={story._id}
              className="border border-gray-200 shadow-sm p-4 rounded mb-6 w-full"
            >
              <div className="flex items-center w-full">
                <div className="flex flex-row justify-between w-full gap-4">
                  <div className="w-1/2">
                    <p className="font-semibold">{story?.storyId?.name}</p>
                    <p className="text-sm leading-normal">
                      Created:{" "}
                      <span className="text-gray-500">
                        {moment().diff(moment(story.createdAt), "hours") < 24
                          ? moment(story.createdAt).fromNow()
                          : moment(story.createdAt).format("MMMM Do YYYY")}
                      </span>
                    </p>
                    {moment().diff(moment(story.createdAt), "minutes") < 15 && (
                      <span className="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded ">
                        Purchased Just Now
                      </span>
                    )}
                  </div>
                  <div className="flex flex-col items-center justify-center gap-4">
                    <button
                      onClick={() => viewStory(story._id)}
                      className="rounded-md transition-all bg-black px-3 py-2 font-semibold text-white shadow-sm hover:opacity-75 text-sm"
                    >
                      View Gift
                    </button>
                    {/* {story.isFilm ? (
                      <button
                        className="rounded-md transition-all w-full bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-700 shadow-sm"
                        disabled
                      >
                        Already a film
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          turnStory(story._id);
                        }}
                        className="rounded-md transition-all bg-white px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 "
                      >
                        Turn into a film
                      </button>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Gift;
