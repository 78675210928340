import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoginModal from '../../Modal/LoginModal';

const PricingCard = ({ title, price, frequency, features, buttonText, color,url,isAuthenticated,subscriptionActive }) => {
  const navigate=useNavigate()
  const [loading, setLoading] = useState(false);
  const makePayment = async () => {
    try {
      setLoading(true);
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY);


      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/checkout/create-subscription`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.success) {
        setLoading(false);
        stripe.redirectToCheckout({ sessionId: response.data.message });
        
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "An unexpected error occurred";
      toast.error(errorMessage);
      setLoading(false);
    }
  };
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [hash]);
  return (
    <div className=" mb-24 text-start md:text-center mx-auto container"
    id={url?.includes('subscription') ? 'subscription' : ''}
    >
      {title && <h1 className="text-3xl text-center mb-5 font-playfair font-semibold">{title}</h1>}
      <div className="mb-2">
        <h1 className="text-4xl font-semibold font-playfair mb-0 text-center">${price}</h1>
        {frequency && <p className="text-sm text-gray-600 block font-playfair text-center">{frequency}</p>}
      </div>
      
      <div
      className='px-8 ml-2'
      >
      <div className="space-y-3 mb-6">
        {features.map((feature, index) => (
          <p key={index} className="text-gray-600">{feature}</p>
        ))}
      </div>
      
     
      </div><div className=' mt-10 flex justify-center'>
     <button 
     disabled={isAuthenticated && subscriptionActive}
    
     onClick={
        ()=>{
          if(url){
            if(url?.includes('subscription')){
              if(!isAuthenticated){
                
                return(
                  <LoginModal/>
                )
              }
              makePayment()
              return
            }
            navigate(url)
          }
        }
     }
        className={` font-lato px-5 py-3 text-sm  ${
          color === 'not-black' 
            ? 'bg-gray-200 text-gray-600'
            : 'bg-black text-white'
        }
        ${isAuthenticated && subscriptionActive ? 'cursor-not-allowed bg-gray-400' : ''}
        
        `}
      >
        {
          loading ? 'Processing...' : buttonText
        }
      </button>
     </div>
    </div>
  );
};
export default PricingCard