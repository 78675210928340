import { useState } from 'react'

export default function InspirationModal({isOpen, setIsOpen,text}) {
  
  return (
    <>

      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-[9999] "
        onClick={() => setIsOpen(false)}
        >
          <div className="bg-white w-full max-w-md rounded-xl shadow-xl max-h-[95%] overflow-auto scroll">
          <p className="font-semibold text-center bg-darkgray p-2 rounded-lg w-60 mx-auto my-6">What can I write about?</p>

            
            <div className="px-6 pt-0 pb-6 max-w-sm mx-auto ">
              <p className="text-start mb-4">
              {text[0]}
              </p>
              
              <ul className="space-y-2 mb-0 ml-2">
              {
                text?.slice(1)?.map((item, index) => (<li className="flex gap-2" key={index} >
                  <span>•</span>
                  <p className="mb-0">
                    {item}
                  </p>
                </li>))
              }
              </ul>

              
            </div>
          </div>
        </div>
      )}
    </>
  )
}