import { Link } from "react-router-dom";
import "./Home.css";
import WhatYouGet from "../WhatYouGet";
import ContentStrategySection from "../ContentStrategySection";
import TurnYourStorySection from "../TurnYourStorySection";
import CarouselComponent from "../ui/Carousel";
import YourValues from "../YourValues";
import Footer from "../Footer";
import { useEffect, useState } from "react";


export const Home = () => {
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 460) {
        // Adjust scroll distance as needed
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <>
      <div>
        <div className="flex-1 flex  justify-center w-full">
          <div className="flex  flex-col mt-28">
            <div className="text-start mb-20 w-[293px] font-lato ml-4">
              {/* <h1 className="font-bold leading-snug text-5xl my-0 tracking-wider">CREATE</h1>
              <h1 className="font-bold leading-snug text-5xl my-0 tracking-wider">YOUR STORY.</h1>
              <h1 className="font-bold leading-snug text-5xl my-0 tracking-wider">TURN IT INTO</h1>
              <h1 className="font-bold leading-snug text-5xl my-0 tracking-wider">MEANINGFUL</h1>
              <h1 className="font-bold leading-snug text-5xl my-0 tracking-wider">CONTENT.</h1>
               */}
               <p
               style={{

fontSize: "20px",
fontStyle: "normal",
fontWeight: 300,
lineHeight: "30px",
letterSpacing: "0.6px",

               }}
               >
               The hardest part of content is knowing what connects. Your story solves that—we’ll create it and turn it into powerful, ready-to-use content.
               </p>
            </div>
            <Link
              to="/start-your-story"
              className={`bg-black text-white w-56  py-3 px-2 text-center transition-all duration-300 font-lato ${
                isFixed
                  ? "fixed bottom-4 left-1/2 transform -translate-x-1/2 z-50 "
                  : "flex justify-center w-56 self-center"
              }`}
            >
              START YOUR STORY
            </Link>
            <p className="text-sm text-gray-500 text-center mt-1">RECEIVE IT IN MINUTES</p>
          </div>
        </div>
      </div>
      
      <div className="px-8 max-w-xl mx-auto flex flex-col space-y-16 mt-20 align-middle "><TurnYourStorySection />
        <section>
          <h3 className="text-center mb-5 font-playfair font-bold">WHAT WE DO</h3>

          <p className="text-start font-lato">
            You want to show what your business stands for but feel stuck,
            unsure how to make it meaningful. We help you unlock your unique
            story and turn it into content that captures your purpose—content
            that’s impossible to copy. Your stories can become albums, memories,
            and social media posts that resonate deeply. It’s not just your
            past; it’s a powerful way to reach people.
          </p>
        </section>
        <WhatYouGet />
        <ContentStrategySection />

        <CarouselComponent />
        <YourValues />
        
        
      </div>
      <Footer />
    </>
  );
};
