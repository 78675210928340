import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCache } from "../../CacheContext";

import ReactGA from "react-ga4";
export const StoryOrderSuccess = () => {
  const navigate = useNavigate();
  const { clearCacheData } = useCache();
  useEffect(() => {
    clearCacheData("stories");
  }, [clearCacheData]);
  useEffect(() => {
    ReactGA.event({
      category: "Story Purchase",
      action: "Story Purchase Success",
      label: "Story Purchase Success",
    });
  }, []);
  return (
    <div>
      <div className="relative z-10" role="dialog" aria-modal="true">
        <div className="fixed inset-0 bg-gray-500/75 transition-opacity"></div>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <div className="relative overflow-hidden rounded-lg bg-white text-center shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="flex flex-col items-center justify-center">
                  {/* <div className="mx-auto flex h-12 w-12 shrink-0 items-center justify-center rounded-full sm:mx-0">
                    <img src="https://melo-story.s3.eu-north-1.amazonaws.com/dollar-coin.png"></img>
                  </div> */}
                  <div className="mt-6 sm:text-left">
                    <h3 className="text-center font-semibold leading-6 text-gray-900">
                      Payment Successful
                    </h3>
                    <div className="mt-3 text-center text-sm text-gray-500">
                      <p>Thank you for your purchase.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-3 px-4 py-3 sm:px-6">
                <button
                  className="rounded-md transition-all w-full bg-black px-3 py-2 font-semibold text-white shadow-sm hover:opacity-75"
                  onClick={() => navigate(`/story`)}
                >
                  See your story
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const PackageOrderSuccess = () => {
  const navigate = useNavigate();
  useEffect(() => {
    ReactGA.event({
      category: "Package Purchase",
      action: "Package Purchase Success",
      label: "Package Purchase Success",
    });
  }, []);
  return (
    <div>
      <div className="relative z-10" role="dialog" aria-modal="true">
        <div className="fixed inset-0 bg-gray-500/75 transition-opacity"></div>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <div className="relative overflow-hidden rounded-lg bg-white text-center shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="flex flex-col items-center justify-center">
                  {/* <div className="mx-auto flex h-12 w-12 shrink-0 items-center justify-center rounded-full sm:mx-0">
                    <img src="https://melo-story.s3.eu-north-1.amazonaws.com/dollar-coin.png"></img>
                  </div> */}
                  <div className="mt-6 sm:text-left">
                    <h3 className="text-center font-semibold leading-6 text-gray-900">
                      Payment Successful
                    </h3>
                    <div className="mt-3 text-center text-sm text-gray-500">
                      <p>
                        Thank you for your purchase. A member from the team will
                        contact you shortly
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-3 px-4 py-3 sm:px-6">
                <button
                  className="rounded-md transition-all w-full bg-black px-3 py-2 font-semibold text-white shadow-sm hover:opacity-75"
                  onClick={() => navigate(`/`)}
                >
                  Home
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export const SubscriptionSuccess = () => {
  const navigate = useNavigate();
  useEffect(() => {
    ReactGA.event({
      category: "Subscription Purchase",
      action: "Subscription Purchase Success",
      label: "Subscription Purchase Success",
    });
  }, []);
  return (<div>
      <div className="relative z-10" role="dialog" aria-modal="true">
        <div className="fixed inset-0 bg-gray-500/75 transition-opacity"></div>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <div className="relative overflow-hidden rounded-lg bg-white text-center shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="flex flex-col items-center justify-center">
                  {/* <div className="mx-auto flex h-12 w-12 shrink-0 items-center justify-center rounded-full sm:mx-0">
                    <img src="https://melo-story.s3.eu-north-1.amazonaws.com/dollar-coin.png"></img>
                  </div> */}
                  <div className="mt-6 sm:text-left">
                    <h3 className="text-center font-semibold leading-6 text-gray-900">
                      Payment Successful
                    </h3>
                    <div className="mt-3 text-center text-sm text-gray-500">
                      <p>
                        Thank you for your subscription.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-3 px-4 py-3 sm:px-6">
                <button
                  className="rounded-md transition-all w-full bg-black px-3 py-2 font-semibold text-white shadow-sm hover:opacity-75"
                  onClick={() => navigate(`/subscription`)}
                >
                  View Subscriptions
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>);
  
};
export const GiftSuccess = () => {
  const navigate = useNavigate();
  useEffect(() => {
    ReactGA.event({
      category: "Gift Purchase",
      action: "Gift Purchase Success",
      label: "Gift Purchase Success",
    });
  }, []);
  return (<div>
      <div className="relative z-10" role="dialog" aria-modal="true">
        <div className="fixed inset-0 bg-gray-500/75 transition-opacity"></div>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <div className="relative overflow-hidden rounded-lg bg-white text-center shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="flex flex-col items-center justify-center">
                  {/* <div className="mx-auto flex h-12 w-12 shrink-0 items-center justify-center rounded-full sm:mx-0">
                    <img src="https://melo-story.s3.eu-north-1.amazonaws.com/dollar-coin.png"></img>
                  </div> */}
                  <div className="mt-6 sm:text-left">
                    <h3 className="text-center font-semibold leading-6 text-gray-900">
                      Payment Successful
                    </h3>
                    <div className="mt-3 text-center text-sm text-gray-500">
                      <p>
                        Thanks for your purchase. A member from the team will contact you shortly.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-3 px-4 py-3 sm:px-6">
                <button
                  className="rounded-md transition-all w-full bg-black px-3 py-2 font-semibold text-white shadow-sm hover:opacity-75"
                  onClick={() => navigate(`/gift`)}
                >
                  View Gifts
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>);
  
};
