import React, { useState, useEffect, useRef, useContext } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Chatbot from "./Chatbot";
import ResultScreen from "./ResultScreen";
import { Link, useLocation, useNavigate } from "react-router-dom";
import StoryCard from "../ui/Card/StoryCard";
import InspirationModal from "./InspirationModal";
import StoryCounter from "./StoryCounter";
import { AuthContext } from "../../context/AuthContext";
import ReactGA from "react-ga4";
const ChatbotPage = () => {
  const [step, setStep] = useState(1);
  const [finalMessg, setFinalMessg] = useState("");
  const [selectedBox, setSelectedBox] = useState("");
  const [isBoxLoading, setIsBoxLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const {allowedStories,isAuthenticated,firstStory} = useContext(AuthContext);
 
  // Refs to each box for scrolling into view
  const boxRefs = useRef([]);
  const scrollContainerRef = useRef(null);
    const navigate = useNavigate();
  const location = useLocation();
const [boxes, setBoxes] = useState([
    {
      title: "BUSINESS STORY",
      cardTitle:"Business Story",
      description: "Why You Started Your Business & Client Stories",
      prompt:"BUSINESS_PROMPT",
      index:0
    },
    {
      title: "MEMORIES",
      cardTitle:"Memories",
      description: "Cherished Moments In Your Life",
      prompt:'MEMORIES_PROMPT',
      index:1
    },
    {
      title: "FOR SOMEONE",
      cardTitle:"For Someone",
      description: "Write a Meaningful Story For Someone Special",
      prompt:'FOR_SOMEONE_PROMPT',
      index:2
    },
    {
      title: "MILESTONE",
      cardTitle:"Milestone",
      description: "Key Life Events",
      prompt:'MILESTONE_PROMPT',
      index:3
    },

  ]);  
  const [text, setText] = useState({
    'BUSINESS STORY':[
      "How your business started is just one story. You have so many more that need to be shared, like:",
      "A client story: from the perspective of your client (you can even get them to write it)",
      "A memorable moment: like a re-branding or a milestone in your career",
      "Overcoming a challenge in business",
      "A business opportunity that came up: like a big event",
      "Your day-to-day: What does a day look like for you?",
      "Anything happen or not happen this week?"
    ],
    MEMORIES:[
      "Memories are powerful, offering insights into who we are and where we’ve been. Here are some ideas to inspire your storytelling:",
      "A Childhood Memory: Something you did during the school holidays, a family holiday.",
      "A Travel Memory: Visiting somewhere new for the first time, an eye opening experience you had.",
      "An Unexpected Moment: A memory of when something surprising or unplanned happened.",
      "A Shared Memory with Someone Special: A moment you shared with family, friends, or even a stranger that has stayed with you.",
      "A funny or embarrassing memory: something that has stuck with you like a story you always tell your friends.",
      "Overcoming a fear or challenge: like trying something that seemed impossible."
    ],
    'FOR SOMEONE':[
      "Writing a story for someone is a meaningful way to honour their impact on your life or share your feelings. Here are some ideas to inspire you:",
      "A Tribute to Someone Who Inspired You: Write about a person who has shaped your life, whether it’s a mentor, family member, or friend.",
      "A Letter to Your Past or Future Self: Reflect on what you’d tell yourself at a different stage of life or what you hope for in the future.",
      "A Story About a Shared Memory: Write about a moment you shared with someone, like a holiday with a friend, journey of parenthood with your partner",
      "A Thank-You Story: Share a story that expresses gratitude for something this person did, no matter how small or significant.",
      "A Story of Admiration: Highlight a quality, achievement, or act of kindness about someone that you deeply admire.",
      "A Story Dedicated to Someone Who Helped You Through a Tough Time: Reflect on how their support made a difference in your life."
    ],
    MILESTONE:[
      "Milestones are defining moments that mark progress and growth in life or work. Here are some ideas to inspire your storytelling:",
      "A Career Milestone: Reflect on a promotion, achievement, or turning point in your professional life.",
      "A Personal Milestone: Write about a significant personal accomplishment, like running a marathon, buying your first home, or mastering a new skill.",
      "A Family Milestone: Share a story about a special family moment, such as a wedding, a new addition to the family, or a reunion.",
      "The First of Many: Write about the first time you did something important—whether it was your first day at a job, your first big sale, or the first time you travelled abroad.",
      "An Unexpected Milestone: Reflect on a moment that became significant without you planning for it, like a chance encounter or a sudden realisation.",
      "A Milestone Worth Celebrating: Share the story of how you marked a special occasion or achievement and what it meant to you like an anniversary or graduation.",
    ]

  });
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tab = searchParams.get("tab");
    
    if (tab) {
      const tabName = tab.replace(/-/g, " ").toUpperCase();
      const box = boxes.find((box) => box.title === tabName);
      setSelectedBox(tabName);
      scrollToCenter(box.index);
      const savedStep = localStorage.getItem(box.title+"step");
      if (savedStep) {
        setStep(parseInt(savedStep, 10));
      }
      else{
        setStep(1);
      }
      const savedFinalMessg = localStorage.getItem(box.title+"finalMessg");

      if (savedFinalMessg) {
        setFinalMessg(savedFinalMessg);
      }
      else{
        setFinalMessg("");
      }
    } else {
      setSelectedBox(""); // Default if no tab is in the URL
    }
  }, []);
  const scrollToCenter = (index) => {
    const selectedBoxElement = boxRefs.current[index];
    const scrollContainer = scrollContainerRef.current;

    if (selectedBoxElement && scrollContainer) {
      const containerWidth = scrollContainer.clientWidth; // Width of the scroll container
      const boxWidth = selectedBoxElement.clientWidth; // Width of the selected box
      const boxOffset = selectedBoxElement.offsetLeft; // Left position of the selected box

      // Calculate the scroll position to center the box
      const scrollPos = boxOffset - containerWidth / 2 + boxWidth / 2 - 20;

      // Smooth scrolling to the calculated position
      scrollContainer.scrollTo({ left: scrollPos, behavior: "smooth" });
    }
  };

  const handleBoxClick = (box, index) => {
    const tabName = box.title.toLowerCase().replace(/\s+/g, "-");
      navigate({ search: `?tab=${tabName}` });
      setSelectedBox(box.title);
      scrollToCenter(index);
      // get the step from local storage
      const savedStep = localStorage.getItem(box.title+"step");
      if (savedStep) {
        setStep(parseInt(savedStep, 10));
      }
      else{
        setStep(1);
      }
      const savedFinalMessg = localStorage.getItem(box.title+"finalMessg");

      if (savedFinalMessg) {
        setFinalMessg(savedFinalMessg);
      }
      else{
        setFinalMessg("");
      }
  };
  useEffect(() => {
    ReactGA.event({
      category: "Story Page",
      action: "Visited Story Page",
      label: "Melo Story Page",
    });
  }, []);

  return (
    <>
      <ToastContainer />
      <InspirationModal 
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        text={text[selectedBox]}
      />
      {
        isAuthenticated && allowedStories!==undefined && allowedStories!=='undefined' && (
          <StoryCounter storiesLeft={allowedStories} />
        )
      }
      {
        selectedBox ?(
          <>
          <div
        className={`w-full fixed left-0 right-0 bg-white px-6 flex items-center justify-center bottom-2 `}
        style={{ zIndex: 1001, overflow: "visible" }} // Ensure overflow is visible
      >
        <div
          ref={scrollContainerRef}
          className="overflow-x-auto whitespace-nowrap box-scrollbar scroll-smooth"
        >
          {boxes.map((box, index) => (
            <div
              key={index}
              ref={(el) => {
                boxRefs.current[index] = el;
              }}
              className={`rounded-xl  flex-shrink-0 inline-flex flex-col items-center mr-4 py-2 px-6 relative
               
                ${
                  box.title === selectedBox
                    ? "bg-mediumgray"
                    : ""
                }
                
                
                `}
              onClick={() => {
                
                handleBoxClick(box, index);
              }}
            >
              <div
                className={`relative  ${
                  isBoxLoading || box.title === selectedBox
                    ? "text-start min-w-[200px]"
                    : "text-start"
                }`}
              >
                {!isBoxLoading || box.title !== selectedBox ? (
                  <div className="text-xs m-0">
                    <div className="flex items-center">
                     
                      <p
                      className="mb-1 font-medium"
                      >{box.title}</p>
                      {/* Optional: Add spinner on "Price and Process" tab */}
                      {/* {box.title === "Price and Process" && !isCleaned && (
                        <i className="fa-solid fa-spinner animate-spin ml-2 text-sm"></i>
                      )} */}
                    </div>
                    <span 
                    className=" text-darkergray"
                    >{box.description}</span>
                  </div>
                ) : (
                  <i className="fa-solid fa-spinner animate-spin"></i>
                )}
              </div>
            </div>
          ))}
        </div>
        
      </div>
      <div className="flex flex-col items-center">
        <div className="flex-1 flex items-center justify-center w-full">
          {step === 1 ? (
            <Chatbot setStep={setStep} setFinalMessg={setFinalMessg} 
              prompt={boxes.find((box) => box.title === selectedBox).prompt}
              stepKey={selectedBox+"step"}
              finalMessgKey={selectedBox+"finalMessg"}
              setIsOpen={setIsOpen}
              allowedStories={allowedStories}
              firstStory={firstStory}
            />
          ) : step === 2 ? (
            <ResultScreen finalMessg={finalMessg} setStep={setStep} 
              prompt={boxes.find((box) => box.title === selectedBox).prompt}
              stepKey={selectedBox+"step"}
              finalMessgKey={selectedBox+"finalMessg"}
              allowedStories={allowedStories}
              firstStory={firstStory}
            />
          ) : null}
        </div>
      </div>

          </>

        ):(
          <>
          <div
        className={`w-full fixed left-0 right-0 bg-white px-7 flex items-center justify-center bottom-6 `}
        style={{ zIndex: 1001, overflow: "visible" }} // Ensure overflow is visible
      >
        <div className="grid grid-cols-2 gap-x-2 gap-y-3">
      {boxes.map((story, index) => (
        <StoryCard
          onClick={() =>
          {
            
          handleBoxClick(story, index)
          }
          }
          key={index}
          title={story.cardTitle}
          description={story.description}
        />
      ))}
    </div>
        
      </div>
      <div class="flex justify-center items-center align-middle pt-24 flex-col">
      <h4>HEY, IT’S SERENA. LET’S GET STARTED.</h4>
      <div
      className="text-center px-6 font-lato flex flex-col space-y-2" 
      >
          <p className="mb-0">
            You can create a story here with me in minutes for{" "}
            <span className="font-bold">{isAuthenticated?firstStory?'free':'$19':"free"}</span>{" "}
            you'll receive:
          </p>
          
          <div className="flex justify-center space-x-2">
          <span>•</span>
            <span>Story</span>
            <span>•</span>
            <span>Content strategy</span>
            <span>•</span>
            <span>Your values</span>
          </div>


</div>
      <p
> Try it below.</p>
        
        </div>
        </>
      )
      }
      
    </>
  );
};

export default ChatbotPage;
