import React, { useEffect, useState } from 'react'
import Modal from '../ui/Modal'
import { useNavigate } from 'react-router-dom'

export default function StoryCounter({ storiesLeft, className = '' }) {
    const [showRestriction, setShowRestriction] = useState(false)
    const navigate = useNavigate()
  useEffect(() => {
    if (storiesLeft === 0) {
      setShowRestriction(true)
      // onRestrict()
    }
  }, [storiesLeft])
  return (
    <>
    {/* <Modal
    isOpen={showRestriction}
    onClose={() => {
                    setShowRestriction(false)
                    navigate('/')
                }}
    title="Story Limit Reached"

    >
        <p className="text-gray-600 mb-6">You've used all your available stories. Please wait until the next cycle to continue.
        </p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={
                () => {
                    setShowRestriction(false)
                    navigate('/')
                }
            }
            className="mt-2 w-full bg-black text-white py-2.5 px-3 rounded"

          >
            Close
          </button>
          
        </div>
    </Modal> */}
    <div className={`fixed right-0 bg-black py-2 px-4 shadow-sm rounded-sm text-md font-medium flex items-center space-x-2 transition-all duration-300 ${className} ${storiesLeft > 0 ? 'text-white' : 'text-red-500'}`}>
      <span className={`w-2 h-2 rounded-full text-black ${storiesLeft > 0 ? 'bg-green-500' : 'bg-red-500'} animate-pulse`}></span>
      <span>{storiesLeft} {storiesLeft === 1 ? 'story' : 'stories'} remaining</span>
    </div></>
    
  )
}

